export const AuthEndpoints = {
    LOGIN: '/auth/login',
    SIGNUP: '/auth/signup',
    VERIFY_OTP: '/auth/verify-otp',
    SEND_OTP: '/auth/send-otp',
    SEND_FORGOT_PASS_LINK: '/auth/forgot-password/send',
    VERIFY_FORGOT_PASS_LINK: '/auth/forgot-password/verify',
    LOGOUT_USER: '/auth/logout',
    FORGOT_PASS_RESET: '/auth/forgot-password/reset',
    GET_USER_DATA: '/auth/user-profile',
}

export const OrgEndpoints = {
    CREATE: '/org/create',
    LIST: '/org/list',
    FETCH_MEMBERS: '/access/org/member-list',
    REVOKE_ACCESS: '/access/org/revoke',
    INVITE_USER: '/access/org/invite',
    UPDATE_ACCESS: '/access/org/update',
}

export const ProjectEndpoints = {
    CREATE: '/project/create',
    LIST: '/project/list',
    UPDATE_PROJECT_NAME: '/project/update-name',
    LIST_PROJECT_MEMBERS: '/access/project/member-list',
    UPDATE_PROJECT_ACCESS: '/access/project/update',
    GENERATE_CI_TOKEN: '/project/gen-ci-token',
    PROJECT_DETAIL: '/project/detail',
    GENERATE_APP_TOKEN: '/project/gen-app-token',
}

export const BucketEndpoints = {
    CREATE: '/bucket/create',
    LIST: '/bucket/list',
    DELETE: '/bucket/delete',
}

export const BundleEndpoints = {
    DOWNLOAD: '/bundle/download',
    UPLOAD: '/bundle/upload',
    LIST: '/bundle/list',
    ADVANCE_LISTING: '/bundle/advance-listing',
    DELETE: '/bundle/delete',
    PROMOTE_BUNDLE: '/bundle/promote',
}

export const PromotedEndpoints = {
    LIST_APP_VERSIONS: '/promoted/list-app-versions',
    LIST_PROMOTED_BUNDLES: '/promoted/promoted-bundle-listing',
    GET_BUNDLE_DETAILS: '/promoted/promoted-bundle-detail',
    UPDATE_RELEASE_DATA: '/promoted/update-release',
}
